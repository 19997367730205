<!--
 * @Author: 刘格优
 * @Date: 2020-03-26 17:22:55
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-07 16:35:03
 -->

<template>
  <div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
    <div class="fetail"
         v-show="!isNull">
      <div class="detailbox">
        <ul>
          <li v-for="(item,index) in lists"
              :key="index">
            <div class="jone">
              <img :src="item.headimg?item.headimg:gwpic.sharelogo"
                   alt="">
            </div>
            <div class="jtwo">
              <p class="jtop">
                {{item.nickname}}
              </p>
              <p class="jbot">
                {{item.rytype == 'M' ?'会员':item.empno}}
              </p>
            </div>
            <div class="jthree">
              <p class="jtop">保费</p>
              <p class="jbot">{{item.mount}}</p>
            </div>
            <div class="jfore">
              <p class="jtop">奖励金</p>
              <p class="jbot">{{item.jl}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getYXInfo } from '@/api/agent/agent'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      lists: [],
      isNull: false,
      gwpic: {}
    };
  },
  created () {
    this.gwpic = getStorage('sys_info', {})
    getYXInfo().then((res) => {
      this.lists = res.data.data
      if (this.lists.length > 0) {
        this.isNull = false
      } else {
        this.isNull = true
      }
    })
  },

  methods: {}
}

</script>
<style scoped lang="stylus">
.fetail {
  /deep/ .md-icon {
    color: red !important;
    font-size: 0.8rem;
    right: 0.5rem !important;
    top: 0.3rem !important;
  }

  .title {
    text-align: center;
    font-size: 0.5rem;
    position: absolute;
    width: 100%;
    top: 0.3rem;
  }

  .detailbox {
    height: 100%;
    overflow-y: auto;
    padding-top: 0.6rem;

    ul {
      li {
        padding: 0.5rem 0;
        border-bottom: 1px solid #ecebeb;

        div {
          display: inline-block;
          text-align: center;

          .jbot {
            position: relative;
            top: 0.3rem;
            color: #8e8c8c;
          }
        }

        .jone {
          width: 25%;

          img {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
          }
        }

        .jtwo {
          width: 35%;
          text-align: left;
          position: relative;
          bottom: 0.5rem;
        }

        .jthree {
          width: 20%;
          position: relative;
          bottom: 0.5rem;
        }

        .jfore {
          width: 20%;
          position: relative;
          bottom: 0.5rem;
        }
      }
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}
</style>